import React from 'react'
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import avatar from "../../assets/img/theme/avatarnew.png";
import Services from "../../RemoteServices/RemoteServices";
import {useHistory} from "react-router-dom";

const PeopleYouMayKnow = (props) => {
    let history = useHistory();
    const showUserProfile = (id) => {
        history.push(`/profile/otherProfile/${id}`);
    }

const followUser=(id)=>{
    const reqDto={
        follower:JSON.parse(localStorage.getItem('user'))?.id,
        following:id,
    }
        Services.followUsers(reqDto).then((response) => {
            props.getPeopleYouMayKnow();
        }).catch(e => {
            throw e
        })
}
    return (
        <Card className={'full-card'} style={{borderRadius:'0px'}}>
            <span style={{padding:'10px',textAlign: 'center', color: 'black', fontWeight: 'bold', fontSize: '16px'}}>People You May Know</span>
            <hr style={{padding:'0px',marginTop:'5px',marginBottom:'5px'}}/>
            {props.peopleList && props.peopleList.length>0?
                <CardBody className={'m-0 p-0'}>
                    {props?.peopleList?.slice(0,5)?.map((item,index)=>{
                        return (
                            <div key={index} style={{
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                marginLeft: '10px',
                                marginBottom: '10px',
                                alignItems: 'center',
                                padding: '10px',
                                borderBottom: '1px solid lightgrey'
                            }}>
                                <img
                                     onClick={()=>showUserProfile(item.id)}
                                    height={60}
                                    width={60}
                                    alt="..."
                                    className="image-span rounded-circle"
                                    src={item.profile_picture?`https://live.englishhub.app${item.profile_picture}`:avatar}
                                />
                                <div className={'m-0 p-0'} style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}>
                                    <span onClick={()=>showUserProfile(item.id)}  className={'image-span ml-4 font-weight-bold'}>{item?.full_name?.substring(0,18)}</span>
                                    <span className={'ml-4'} style={{fontSize: 12}}>Kathmandu, Nepal</span>
                                    <Button className={'ml-4 mt-2'} style={{
                                        width: '120px',
                                        backgroundColor: 'white',
                                        color: 'purple',
                                        padding: '5px',
                                        borderRadius: '30px',
                                        borderBottom: '1px solid lightgrey'
                                    }}
                                            size={'sm'}
                                            onClick={()=>followUser(item.id)}
                                    >
                                        <div style={{display: 'flex', width:'100%',justifyContent:'center'}}>
                                            <span>Follow</span>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        )})
                    }
                </CardBody>
                :
                <CardBody>
                    <h5 style={{textAlign:"center",color:'purple'}}>No people list found</h5>
                </CardBody>
            }
        </Card>
    )
}
export default PeopleYouMayKnow