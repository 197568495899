import React, { memo, useContext, useMemo, useState } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { MobXContext } from "MobXProvider";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faDownload, faEllipsis, faThumbsUp as faThunbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as faThumbsUpRegular } from '@fortawesome/free-regular-svg-icons'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
const FreeComment = ({ comment, lesson }) => {
    let history = useHistory();
    const showUserProfile = (id) => {
        history.push(`/profile/otherProfile/${id}`);
    }
    const { likeFreeReview, deleteFreeReview, deleteFreeReply, openCommentForm } =
        useContext(MobXContext);
    const user = JSON.parse(localStorage.getItem("user"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isSameUser = useMemo(() => comment.user.id === user.id, []);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const [ReplayContOpen, setReplayContOpen] = useState(false)
    return (
        <>
            <Grid container spacing={2}
                style={{
                    margin: '0',
                    paddingLeft: '4px',
                    scrollBehavior:'smooth',
                    overflow:'visible'
                }}
                wrap="nowrap">
                <Grid item>
                    <Avatar
                        style={{
                            height: '35px',
                            width: '35px'
                        }}
                        alt={comment.user.full_name}
                        src={`https://live.englishhub.app${comment.user.profile_picture}`}
                    />
                </Grid>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid style={{
                                width: '100%'
                            }} container spacing={1} wrap="nowrap">
                                <Grid item xs>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row'
                                        }}

                                        className={'nameClick'}
                                        gutterBottom >
                                        <span onClick={() => showUserProfile(comment.user.id)}
                                            style={{ fontWeight: 'bold', fontSize: '0.8rem' }}>
                                            {comment.user.full_name}&nbsp;
                                            {comment?.user?.email_verified && <i style={{ color: 'blue', fontSize: 14 }} className={' fa fa-check-circle'}></i>}
                                        </span>
                                        <span style={{ fontSize: 12 }}
                                            className={'ml-1'}> {moment(comment.created_at).fromNow()}</span>
                                        {isSameUser && (
                                            <Dropdown
                                                isOpen={dropdownOpen}
                                                toggle={toggleDropdown}
                                                className=""
                                            >
                                                <DropdownToggle tag="span" className="">
                                                    <FontAwesomeIcon icon={faEllipsis} size="l" />
                                                </DropdownToggle>
                                                <DropdownMenu className="" >
                                                    <DropdownItem onClick={() => openCommentForm(comment)}>
                                                        Edit
                                                    </DropdownItem>
                                                    <DropdownItem
                                                        onClick={() =>
                                                            comment.review
                                                                ? deleteFreeReply({ id: comment.id, review: comment.review })
                                                                : deleteFreeReview(comment.id)
                                                        }
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{

                        }} item xs={12}>
                            <Typography style={{
                                wordBreak: "break-word",
                                // maxWidth: '96%',
                                 whiteSpace: 'break-spaces'
                            }} >{comment.message}</Typography>
                        </Grid>
                    </Grid>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1px',
                        alignItems: 'center',
                        alignContent: 'center'
                    }} >
                        <Button
                            size="small"
                            style={{ textTransform: "unset" }}
                            onClick={() =>
                                likeFreeReview({
                                    id: comment.review ? undefined : comment.id,
                                    reply_id: comment.review ? comment.id : undefined,
                                    have_i_Like: !comment.have_i_Like,
                                    review: comment.review,
                                    current_user_like_status: !comment.current_user_like_status,
                                    auth_user: isSameUser ? undefined : user.id,
                                    status: !comment.current_user_like_status,
                                })
                            }
                        >

                            {
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '5px',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        color: comment.current_user_like_status ? '#4f2bab' : ''
                                    }}>
                                        <FontAwesomeIcon icon={comment.current_user_like_status ? faThunbsUpSolid : faThumbsUpRegular} />
                                        <span
                                            style={{ color: '#002060' }}
                                            className={'font-weight-bold like-hover'}>
                                            {comment.like_count > 0 ? ` (${comment.like_count})` : 0}
                                        </span>
                                    </div>
                                </div>

                            }


                        </Button>
                        <Button
                            size="small"
                            style={{ textTransform: "unset" }}
                            onClick={() =>
                                openCommentForm({
                                    message: `@${comment.user.full_name} `,
                                    reply_id: comment.review ? comment.id : undefined,
                                    review: comment.review || comment.id,
                                    lesson,
                                })
                            }
                        >
                            <span className=" text-xs" > Reply</span>
                        </Button>
                        {Array.isArray(comment.reply) && comment?.reply.length > 0 && (
                            <div
                                className=""
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: '0.7rem',
                                    color: 'black'
                                }}
                                onClick={() => setReplayContOpen(!ReplayContOpen)}
                            >
                                <div className="">
                                    <span className="  " > {comment?.reply.length} </span>
                                    <span className="  " > Reply</span>
                                </div>
                                <FontAwesomeIcon
                                    icon={ReplayContOpen ? faAngleUp : faAngleDown}
                                    className=""

                                />
                            </div>
                        )}

                    </div>
                </Grid>
            </Grid >
            {ReplayContOpen &&
                Array.isArray(comment.reply) && (
                    <div style={{ marginLeft: 20,overflowY:'scroll' }}>
                        {comment.reply.map((node) => (
                            <>
                                <FreeComment comment={node} key={node.id} lesson={lesson}>
                                </FreeComment>
                            </>
                        ))}
                    </div>
                )
            }


        </>
    );
};

export default memo(FreeComment);
