/* eslint-disable no-lone-blocks */
import React, {useState, useEffect} from 'react'
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormGroup,
    Input,
    Modal,
    Row
} from 'reactstrap'
import './communityStyles.css'
import {withSnackbar} from 'notistack';
import avatar from "../../assets/img/theme/avatarnew.png";
import like from "../../assets/img/theme/like.png";
import liked from "../../assets/img/theme/liked.png";
import image from '../../assets/img/theme/profile-cover.jpg'
import {Oval, ThreeDots} from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import {useHistory} from "react-router-dom";
import Sort from "../../assets/img/threedot.png";
import AnnouncementField from "./AnnouncementField";

const Services = require('../../RemoteServices/RemoteServices.js');

const CommunityPostList = (props) => {

    let history = useHistory();
    const showUserProfile = (id) => {
        const localUser = JSON.parse(localStorage.getItem('user'))?.id;
        if (localUser === id) {
            history.push('/profile/personalProfile')
        } else {
            history.push(`/profile/otherProfile/${id}`);
        }
    }
    let clickHandler = null
    const user = JSON.parse(localStorage.getItem('user'));
    const [commentLike, setCommentLike] = useState({
        like_count: '',
        current_user_like_status: ''
    })
    const [showLike, setShowLike] = useState(false)
    const [showComment, setShowComment] = useState(false)
    const [showCommentReply, setShowCommentReply] = useState("")
    const [commentMessage, setCommentMessage] = useState('')
    const [commentReplyMessage, setCommentReplyMessage] = useState('')
    const [showShare, setShowShare] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState({
        commentMessages: '',
    })
    const [commentReplySuccess, setCommentReplySuccess] = useState({
        commentMessages: '',
    })
    let [announcementData, setAnnouncementData] = useState({
        message: '',
        image: ""
    })
    let [ImagePreview, setImagePreview] = useState('');
    const [communityList, setCommunityList] = useState(props.communityList || [])
    const [commentContent, setCommentContent] = useState({
        comment: [],
    })
    const [showMore, setShowMore] = useState(null);
    const [showLess, setShowLess] = useState(null);
    const [showEditDelete, setShowEditDelete] = useState(null);
    const [showEditDeleteReply, setShowEditDeleteReply] = useState(null);
    const [showEditDeleteComment, setShowEditDeleteComment] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteItem, setDeleteItem] = useState();
    const [showEdit, setShowEdit] = useState(false);
    const [showEditItem, setShowEditItem] = useState();
    const [likeList, setLikeList] = useState()
    const [showEditComment, setShowEditComment] = useState(false)
    const [showDeleteComment, setShowDeleteComment] = useState(false)
    const [editCommentMessage, setShowEditCommentMessage] = useState();
    const [editCommentContent, setShowEditCommentContent] = useState();
    const [deleteCommentId, setShowDeleteCommentId] = useState();

    const [showEditReplyComment, setShowEditReplyComment] = useState(false)
    const [showDeleteReplyComment, setShowDeleteReplyComment] = useState(false)
    const [editCommentReplyMessage, setShowEditCommentReplyMessage] = useState();
    const [editCommentReplyContent, setShowEditCommentReplyContent] = useState();
    const [deleteCommentReplyId, setShowDeleteCommentReplyId] = useState()


    //comment edit reply modal
    const showEditCommentReplyModal = async (item) => {
        setShowEditReplyComment(!showEditComment)
        setShowEditCommentReplyMessage(item.message);
        setShowEditCommentReplyContent(item)
    }
    //update Edit Comment
    const updateEditCommentReply = async () => {
        if (editCommentReplyMessage) {
            const updatedComment = {
                message: editCommentReplyMessage,
                id: editCommentReplyContent.id,
                post_id: commentContent.id
            }

            await Services.updateCommentReplyCommunity(updatedComment).then((response) => {
                setCommentContent((prevCommentContent) => ({
                    ...prevCommentContent,
                    comments: response.data,
                }));
                setShowEditReplyComment(!showEditReplyComment)
            }).catch(e => {
            });
        } else {

        }
    };

    //comment delete Modal
    const showDeleteCommentReplyModal = async (item) => {
        setShowDeleteReplyComment(!showDeleteReplyComment)
        setShowDeleteCommentReplyId(item?.id);
    }
    const deleteCommunityCommentReplyPost = () => {
        Services.deleteCommunityCommentReplyList({id: deleteCommentReplyId}).then((response) => {
            setCommentContent((prevCommentContent) => {
                // Create a copy of the previous state
                const updatedContent = {...prevCommentContent};

                // Loop through the comments array
                updatedContent.comments = updatedContent.comments.map((comment) => {
                    // Check if the comment has a reply array
                    if (comment.reply && Array.isArray(comment.reply)) {
                        // Filter out the reply with the specified ID
                        comment.reply = comment.reply.filter((reply) => reply.id !== deleteCommentReplyId);

                        // Update the comment's reply count
                        comment.commentreply_count = comment.reply.length;
                    }

                    return comment;
                });

                // Return the updated state
                return updatedContent;
            });
            setShowDeleteReplyComment(!showDeleteReplyComment)
        }).catch(e => {
            props.enqueueSnackbar("Internal Server Error", {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        });
    }


    //comment edit modal
    const showEditCommentModal = async (item) => {
        setShowEditComment(!showEditComment)
        setShowEditCommentMessage(item.message);
        setShowEditCommentContent(item)
    }
    //update Edit Comment
    const updateEditComment = async () => {
        if (editCommentMessage) {
            const updatedComment = {
                message: editCommentMessage,
                id: editCommentContent.id,
                post_id: commentContent.id
            }

            await Services.updateCommentCommunity(updatedComment).then((response) => {
                setCommentContent((prevCommentContent) => ({
                    ...prevCommentContent,
                    comments: response.data,
                }));
                setShowEditComment(!showEditComment)
            }).catch(e => {
            });
        } else {

        }
    };

    //comment delete Modal
    const showDeleteCommentModal = async (item) => {
        setShowDeleteComment(!showDeleteComment)
        setShowDeleteCommentId(item?.id);
    }
    const deleteCommunityCommentPost = () => {
        Services.deleteCommunityCommentList(deleteCommentId).then((response) => {
            setShowDeleteComment(!showDeleteComment)
            setCommentContent((prevCommentContent) => ({
                ...prevCommentContent,
                comments: prevCommentContent.comments.filter(comment => comment.id !== deleteCommentId),
                comment_count: prevCommentContent.comment_count - 1
            }));

        }).catch(e => {
            props.enqueueSnackbar("Internal Server Error", {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        });
    }


    const showEditModal = async (item) => {
        setShowEdit(!showEdit)
        setShowEditItem(item);
    }


    const showLikeModal = async (item) => {
        await setLikeList(item)
        await setShowLike(!showLike)

    }


    const showDeleteModal = async (item) => {
        setShowDelete(!showDelete);
        setDeleteItem(item);
    }


    const showEditDeleteToggle = (index) => {
        if (showEditDelete === index) {
            setShowEditDelete(null)
        } else {
            setShowEditDelete(index)
        }
    }

    const showEditDeleteReplyToggle = (index) => {
        if (showEditDeleteReply === index) {
            setShowEditDeleteReply(null)
        } else {
            setShowEditDeleteReply(index)
        }
    }

    const showEditDeleteCommentToggle = (index) => {
        if (showEditDeleteComment === index) {
            setShowEditDeleteComment(null)
        } else {
            setShowEditDeleteComment(index)
        }
    }

    const deleteCommunityPost = (item) => {
        Services.deleteCommunity(item ? item : deleteItem).then((response) => {
            props.getCommunity();
            setShowDelete(!showDelete)
        }).catch(e => {
            props.enqueueSnackbar("Internal Server Error", {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        });
    }

    const setImageInPost = (event) => {
        const {files} = event.target
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        setImagePreview(window.URL.createObjectURL(files[0]))
        reader.onload = function () {
            setAnnouncementData({...announcementData, image: reader.result})
        }
    }


    const showMoreDetail = (index) => {
        setShowMore(index)
        setShowLess(null)
    }

    const showLessDetail = (index) => {
        setShowLess(index)
        setShowMore(null)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleComment();
        }
    }

    const handleCommentKeyDown = (e) => {
        if (e.key === 'Enter') {
            updateEditComment();
        }
    }
    const handleCommentReplyKeyDown = (e) => {
        if (e.key === 'Enter') {
            updateEditCommentReply();
        }
    }

    const handleReplyKeyDown = (e, item) => {
        if (e.key === 'Enter') {
            handleReplyComment(item);
        }
    }

    const handleComment = async () => {
        if (commentMessage) {
            await Services.addCommentCommunity({
                // user: JSON.parse(localStorage.getItem('user'))?.id,
                message: commentMessage,
                post: commentContent.id,
                comment_type: 'C'
            }).then((response) => {
                setCommentContent((prevCommentContent) => ({
                    ...prevCommentContent,
                    comments: [response, ...prevCommentContent.comments],
                    comment_count: prevCommentContent.comment_count + 1
                }));
                clearCommentMessage();
                setCommentSuccess({commentMessages: 'Successfully Posted'})
                setTimeout(() => {
                    setCommentSuccess({
                        commentMessages: ''
                    })
                }, 2000);
            }).catch(e => {
                setCommentSuccess({
                    commentMessages: e
                })
                setTimeout(() => {
                    setCommentSuccess({
                        commentMessages: ''
                    })
                }, 2000)
            });
        } else {
            setCommentSuccess({
                commentMessages: 'Please Enter Message'
            })
            setTimeout(() => {
                setCommentSuccess({
                    commentMessage: ''
                })
            }, 2000)
        }
    };

    const showCommentList = (item, index) => {
        setShowComment(true)
        setCommentContent(item)
        setCommentLike({
            like_count: item.like_count,
            current_user_like_status: item.current_user_like_status
        })
    }

    const clearCommentMessage = async () => {
        await setCommentMessage('')
    }

    const clearCommentReplyMessage = async () => {
        await setCommentReplyMessage('')
    }

    const handleReplyComment = async (comment) => {
        if (commentReplyMessage) {
            await Services.addCommentReplyCommunity({
                // user: JSON.parse(localStorage.getItem('user'))?.id,
                message: commentReplyMessage,
                postcomment: comment.id,
                comment_type: 'R'
            }).then((response) => {
                setCommentContent((prevCommentContent) => ({
                    ...prevCommentContent,
                    comments: prevCommentContent.comments.map((comment) => {
                        if (comment.id) { // Assuming you want to update the comment with id 50
                            return {
                                ...comment,
                                reply: [response.data, ...comment.reply],
                            };
                        }
                        return comment;
                    }),
                }));
                clearCommentReplyMessage();
                setCommentReplySuccess({commentMessages: 'Successfully Posted'})
                setTimeout(() => {
                    setCommentReplySuccess({
                        commentMessages: ''
                    })
                }, 2000);
            }).catch(e => {
                setCommentReplySuccess({
                    commentMessages: e
                })
                setTimeout(() => {
                    setCommentReplySuccess({
                        commentMessages: ''
                    })
                }, 2000)
            });
        } else {
            setCommentReplySuccess({
                commentMessages: 'Please Enter Message'
            })
            setTimeout(() => {
                setCommentReplySuccess({
                    commentMessage: ''
                })
            }, 2000)
        }
    };

    //inside comment like
    const handleCommentReplyLike = (likeData, index) => {
        let reqDto = {
            have_i_Like: !likeData.current_user_like_status,
            id: likeData.id,
        }
        Services.likeReplyCommunity(reqDto).then((response) => {

            const updatedCommentContent = [...commentContent.comments];
            if (updatedCommentContent[index].current_user_like_status) {
                updatedCommentContent[index].like_count--;
            } else {
                updatedCommentContent[index].like_count++;
            }
            updatedCommentContent[index].current_user_like_status = !updatedCommentContent[index].current_user_like_status;
            setCommentContent((prevState) => ({
                ...prevState,
                updatedCommentContent
            }));

            props.enqueueSnackbar(likeData.current_user_like_status ? 'Liked' : 'Unliked', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )

        }).catch = (err) => {
            props.enqueueSnackbar(err, {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        }

    }

    const handleReplyCommentLike = (likeData, index) => {
        let reqDto = {
            have_i_Like: !likeData.current_user_like_status,
            reply_id: likeData.id,
        }
        Services.likeReplyCommunity(reqDto).then((response) => {

            const updatedCommentContent = commentContent.comments.map((comment) => {
                if (comment.id) { // Assuming you want to update the comment with id 50
                    const updatedReply = comment.reply.map((reply) => {
                        if (reply.id === likeData.id) { // Assuming you want to update the reply with id 14
                            const updatedLikeCount = reply.current_user_like_status ? reply.like_count - 1 : reply.like_count + 1;
                            return {
                                ...reply,
                                like_count: updatedLikeCount,
                                current_user_like_status: !reply.current_user_like_status,
                            };
                        }
                        return reply;
                    });

                    return {
                        ...comment,
                        reply: updatedReply,
                    };
                }
                return comment;
            });

            setCommentContent((prevState) => ({
                ...prevState,
                comments: updatedCommentContent,
            }));

            props.enqueueSnackbar(!likeData.current_user_like_status ? 'Liked' : 'Unliked', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )

        }).catch = (err) => {
            props.enqueueSnackbar(err, {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        }

    }


    const handleCommentReplyCommentShowModal = async (index) => {
        await setShowCommentReply(index)
    }

    //comment like
    const handleCommentLike = (likeData) => {
        let reqDto = {
            have_i_Like: !commentLike.current_user_like_status,
            id: likeData.id,

        }
        Services.likeCommunity(reqDto).then((response) => {
            setCommentLike({
                like_count: commentLike.current_user_like_status ? commentLike.like_count - 1 : commentLike.like_count + 1,
                current_user_like_status: !commentLike.current_user_like_status
            })
            props.enqueueSnackbar(!commentLike.current_user_like_status ? 'Liked' : 'Unliked', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )

        }).catch = (err) => {
            props.enqueueSnackbar(err, {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        }
    }

    //list like
    const likeCommunityPost = (likeData, index) => {
        const updatedCommunityList = [...communityList];
        if (updatedCommunityList[index].current_user_like_status) {
            updatedCommunityList[index].like_count--;
        } else {
            updatedCommunityList[index].like_count++;
        }
        updatedCommunityList[index].current_user_like_status = !updatedCommunityList[index].current_user_like_status;
        setCommunityList(updatedCommunityList);

        let reqDto = {
            have_i_Like: likeData.current_user_like_status,
            id: likeData.id,

        }
        Services.likeCommunity(reqDto).then((response) => {
            props.enqueueSnackbar(likeData.current_user_like_status ? 'Liked' : 'Unliked', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )

        }).catch = (err) => {
            props.enqueueSnackbar(err, {
                    variant: 'danger',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
            )
        }

    }

    useEffect(() => {
        setCommunityList(props.communityList.results)
    });

    const closeCommentModal = async () => {
        await props.getCommunity();
        setShowComment(false);
        setShowCommentReply(null)

    }

    const removeImage = () => {
        setAnnouncementData({...announcementData, image: ""})
    }

    return (
        <>
            {props.communityList && communityList?.length > 0 ?
                <InfiniteScroll
                    style={{overflow: 'none'}}
                    dataLength={props?.communityList?.results?.length}
                    hasMore={props?.communityList?.count > props.communityList?.results?.length}
                    next={() => props.getCommunity(props.communityList.next)}
                    loader={<div
                        className={'h-100 d-flex align-items-center justify-content-center'}>
                        <Oval
                            height="30"
                            width="30"
                            radius="12"
                            color="grey"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                        />
                    </div>}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                    scrollableTarget="scrollableDiv"
                >
                    {communityList && communityList?.length > 0 && communityList?.map((item, index) => {
                        return (
                            <div className="communityPostList" key={index}>
                                <div className={'communityCard'}>
                                    <Row style={{width: '100%'}}>
                                        <Col xl={12} className={'m-0 p-0'}
                                             style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                            <Col xl={1}>
                                                <img
                                                    onClick={() => showUserProfile(item.user.id)}
                                                    style={{cursor: 'pointer'}}
                                                    height={50}
                                                    width={50}
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={item?.user?.profile_picture || avatar}
                                                />
                                            </Col>
                                            <Col xl={10} className={'m-0 p-0'} style={{display: 'flex', width: '100%'}}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                }}>
                                               <span onClick={() => showUserProfile(item.user.id)}
                                                     className={'link-span'}
                                                     style={{
                                                         fontSize: '18px',
                                                         fontWeight: 'bold'
                                                     }}>{item?.user?.full_name}</span>


                                                    <span className={'link-span'}>
                                            {new Date(item?.modified_at).toLocaleString('en-US',
                                                {
                                                    weekday: 'long',
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                    hour: 'numeric',
                                                    minute: 'numeric',
                                                    second: 'numeric'
                                                })}
                                            </span>
                                                </div>
                                            </Col>
                                            <Col xl={1}>
                                                {JSON.parse(localStorage.getItem('user'))?.id === item.user.id &&
                                                    <Dropdown toggle={() => showEditDeleteToggle(index)}
                                                              style={{backgroundColor: 'transparent', minWidth: '4rem'}}
                                                              isOpen={showEditDelete === index ? true : false}>
                                                        <DropdownToggle style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            backgroundColor: 'transparent',
                                                            boxShadow: 'none',
                                                            border: 'none',
                                                        }}><img height={15} alt={'sort'}
                                                                src={Sort}/></DropdownToggle>
                                                        <DropdownMenu style={{minWidth: '4rem', width: '4rem'}}>
                                                            <DropdownItem
                                                                onClick={() => showEditModal(item)}>Edit</DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => showDeleteModal(item)}>Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                }
                                            </Col>
                                        </Col>

                                        <Col xl={12}
                                             style={{marginLeft: '10px', marginTop: '10px', marginBottom: '10px'}}>
                                            {item.message.length > 500 || showLess === index ?
                                                <>
                                                    <div style={{
                                                        whiteSpace: 'pre-line',
                                                    }}>{item.message.substring(0, 500)}</div>
                                                    {showMore === null &&
                                                        <span className={'show-more'}
                                                              onClick={() => showMoreDetail(index)}>...Show More</span>}
                                                </>
                                                :
                                                <div style={{
                                                    whiteSpace: 'pre-line',
                                                }}>{item.message}</div>
                                            }
                                            {showMore === index &&
                                                <>
                                                    <div style={{
                                                        whiteSpace: 'pre-line',
                                                    }}>{item.message}</div>
                                                    <span className={'m-0 p-0 show-more'}
                                                          onClick={() => showLessDetail(index)}>Show Less</span>
                                                </>
                                            }

                                        </Col>
                                        <Col xl={12} style={{marginLeft: '10px'}}>
                                            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                                <img
                                                    style={{maxWidth: '100%', width: '100%', height: 'auto'}}
                                                    src={item.image}
                                                />
                                            </div>
                                        </Col>
                                        <Col xl={12} style={{marginLeft: '10px', marginTop: '20px'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                paddingBottom: '5px',
                                                borderBottom: '1px solid lightgrey'
                                            }}>
                                    <span className={'link-span'}
                                          onClick={() => showLikeModal(item.likes)}>{item.like_count} Likes</span>
                                                <span className={'link-span'}
                                                      onClick={() => showCommentList(item, index)}>{item.comment_count} Comments</span>
                                                <span className={'link-span'}
                                                      onClick={() => setShowShare(true)}>{item.share_count} Shares</span>
                                            </div>
                                        </Col>
                                        <Col xl={12} style={{marginLeft: '10px', marginTop: '10px'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}>
                                                {item.current_user_like_status ?
                                                    <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                                        <img className={'like-hover'}
                                                             onClick={() => likeCommunityPost(item, index)} src={liked}
                                                             height={20} width={20}/>
                                                        <span style={{color: '#002060'}}
                                                              className={'font-weight-bold like-hover'}
                                                              onClick={() => likeCommunityPost(item, index)}>Liked</span>
                                                    </div> :
                                                    <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                                        <img className={'like-hover'}
                                                             onClick={() => likeCommunityPost(item, index)} src={like}
                                                             height={20} width={20}/>
                                                        <span className={'like-hover'}
                                                              onClick={() => likeCommunityPost(item, index)}>Like</span>
                                                    </div>
                                                }
                                                <i onClick={() => showCommentList(item, index)} style={{
                                                    fontSize: 16,
                                                    color: '#717891', cursor: 'pointer'
                                                }}
                                                   className={'fa fa-comment'}>&nbsp;Comment</i>
                                                <i style={{fontSize: 16, color: '#717891'}}
                                                   className={'fa fa-share'}>&nbsp;Share</i>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )
                    })}
                </InfiniteScroll> :
                <>
                    {!props.communityLoading &&
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', color: 'purple'}}>No
                            Community Posts..</div>
                    }
                </>
            }


            {props.communityLoading &&
                <div style={{display: 'flex', with: '100%', justifyContent: 'center'}}>
                    <ThreeDots
                        height="50"
                        width="50"
                        color="#6C32A0"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            }


            {showLike &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-md"
                    isOpen={showLike}
                    toggle={() => setShowLike(!showLike)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                             paddingLeft: '200px'
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Like
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowLike(!showLike)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-body'}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginBottom: '10px',
                            width: '100%'
                        }}>
                            {likeList && likeList?.map((item, index) => {
                                return (
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            justifyContent: 'space-between',
                                            marginBottom: '10px',
                                            alignItems: 'center',
                                        }} key={index}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center'
                                            }}>
                                                <img
                                                    onClick={() => showUserProfile(item.user.id)}
                                                    style={{cursor: 'pointer'}}
                                                    height={50}
                                                    width={50}
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={item.user.profile_picture}
                                                />
                                                <span className={'link-span'}
                                                      onClick={() => showUserProfile(item.user.id)}
                                                      style={{
                                                          fontSize: '18px',
                                                          fontWeight: 'bold'
                                                      }}>{item.user.full_name}</span>
                                            </div>
                                            <Button style={{
                                                margin: '0px',
                                                padding: '5px',
                                                backgroundColor: 'purple',
                                                color: 'white'
                                            }}
                                                    className={'btn btn-sm'}>Follow</Button>
                                        </div>
                                    </>
                                )
                            })
                            }

                        </div>

                    </div>
                </Modal>
            }

            {showComment &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showComment}
                    toggle={() => closeCommentModal()}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                             paddingLeft: '320px'
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Comment
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => closeCommentModal()}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-body'}>
                        <div className={'communityCard'}>
                            <Row style={{width: '100%'}}>
                                <Col xl={12} className={'m-0 p-0'}
                                     style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                    <Col className={'mobile-col'} xl={1}>
                                        <img
                                            onClick={() => showUserProfile(commentContent.user.id)}
                                            style={{cursor: 'pointer'}}
                                            height={50}
                                            width={50}
                                            alt="..."
                                            className="rounded-circle"
                                            src={commentContent?.user?.profile_picture}
                                        />
                                    </Col>
                                    <Col xl={11} className={'m-0 p-0'} style={{display: 'flex', width: '100%'}}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '100%',
                                        }}>
                                        <span onClick={() => showUserProfile(commentContent.user.id)}
                                              className={'link-span'}
                                              style={{
                                                  fontSize: '18px',
                                                  fontWeight: 'bold'
                                              }}>{commentContent?.user?.full_name}</span>
                                            <span className={'link-span'}>
                                                 {new Date(commentContent?.modified_at).toLocaleString('en-US',
                                                     {
                                                         weekday: 'long',
                                                         year: 'numeric',
                                                         month: 'long',
                                                         day: 'numeric',
                                                         hour: 'numeric',
                                                         minute: 'numeric',
                                                         second: 'numeric'
                                                     }) || 'N/A'}
                                            </span>
                                        </div>
                                    </Col>
                                </Col>

                                <Col xl={12}
                                     style={{marginLeft: '10px', marginTop: '10px', marginBottom: '10px'}}>
                                    <div style={{whiteSpace: 'pre-line'}}>{commentContent?.message}</div>
                                </Col>
                                <Col xl={12} style={{marginLeft: '10px'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                        <img
                                            style={{maxWidth: '100%', height: 'auto'}}
                                            src={commentContent?.image}
                                        />
                                    </div>
                                </Col>
                                <Col xl={12} style={{marginLeft: '10px', marginTop: '20px'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        paddingBottom: '5px',
                                        borderBottom: '1px solid lightgrey'
                                    }}>
                                        <span className={'link-span'}
                                              onClick={() => handleCommentLike(commentContent)}>{commentLike.like_count} Likes</span>
                                        <span className={'link-span'}
                                              onClick={() => setShowComment(true)}>{commentContent?.comment_count} Comments</span>
                                        <span className={'link-span'}
                                              onClick={() => setShowShare(true)}>{commentContent?.share_count} Shares</span>
                                    </div>
                                </Col>
                                <Col xl={12} style={{marginLeft: '10px', marginTop: '10px'}}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}>
                                        {commentLike.current_user_like_status ?
                                            <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                                <img className={'like-hover'}
                                                     onClick={() => handleCommentLike(commentContent)} src={liked}
                                                     height={20} width={20}/>
                                                <span style={{color: '#002060'}}
                                                      className={'font-weight-bold like-hover'}
                                                      onClick={() => handleCommentLike(commentContent)}>Liked</span>
                                            </div> :
                                            <div style={{display: 'flex', flexDirection: 'row', gap: '5px'}}>
                                                <img className={'like-hover'}
                                                     onClick={() => handleCommentLike(commentContent)} src={like}
                                                     height={20} width={20}/>
                                                <span className={'like-hover'}
                                                      onClick={() => handleCommentLike(commentContent)}>Like</span>
                                            </div>
                                        }
                                        <i style={{fontSize: 16, color: '#717891'}}
                                           className={'fa fa-comment'}>&nbsp;Comment</i>
                                        <i style={{fontSize: 16, color: '#717891'}}
                                           className={'fa fa-share'}>&nbsp;Share</i>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={'commentBox'} style={{padding: '10px'}}>
                            <Input
                                className={'search-input'}
                                type="text"
                                name="comment"
                                value={commentMessage}
                                onChange={(event) => setCommentMessage(event.target.value)}
                                placeHolder={'Reply a comment'}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                        <div className={'mt-3'} style={{width: '100%'}}>
                            <Button style={{
                                backgroundColor: '#6C32A0',
                                color: 'white'
                            }}
                                    className={'btn btn-sm float-right'}
                                    onClick={() => handleComment()}>Comment</Button>
                            <Button style={{
                                backgroundColor: '#ff1010',
                                color: 'white'
                            }}
                                    className={'btn btn-sm float-right'}
                                    onClick={() => clearCommentMessage()}>Cancel</Button>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                                {commentSuccess.commentMessages && <span
                                    style={{
                                        color: 'red',
                                        marginLeft: '10px'
                                    }}>{commentSuccess.commentMessages}</span>}
                            </div>
                        </div>
                        <br/>
                        {commentContent?.comments?.length > 0 && commentContent?.comments?.map((item, index) => {
                            return (
                                <div>
                                    <div className={'card-image'} key={index}
                                         style={{display: 'flex', flexDirection: "row", marginBottom: '6px'}}>
                                        <img alt={'review'}
                                             onClick={() => showUserProfile(item.user.id)}
                                             src={item.user.profile_picture || avatar}
                                             height={50}
                                             style={{
                                                 cursor: 'pointer',
                                                 borderRadius: '50%',
                                                 objectFit: 'cover'
                                             }}
                                             width={50}
                                        />

                                        <div className={'card-name-star'}>
                                            <span style={{cursor: 'pointer'}}
                                                  onClick={() => showUserProfile(item.user.id)}
                                                  className={'ml-2 font-weight-bold'}>{item.user.full_name}</span>
                                            <div className={'star'}>
                                    <span style={{fontSize: '12px'}} className={'ml-2 mt-0'}>
                                         {new Date(item?.created_at).toLocaleString('en-US',
                                             {
                                                 weekday: 'long',
                                                 year: 'numeric',
                                                 month: 'long',
                                                 day: 'numeric',
                                                 hour: 'numeric',
                                                 minute: 'numeric',
                                                 second: 'numeric'
                                             })}</span>
                                            </div>
                                        </div>
                                        {JSON.parse(localStorage.getItem('user'))?.id === item.user.id &&
                                            <Dropdown toggle={() => showEditDeleteCommentToggle(index)}
                                                      style={{backgroundColor: 'transparent', minWidth: '4rem'}}
                                                      isOpen={showEditDeleteComment === index ? true : false}>
                                                <DropdownToggle style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 'none',
                                                    border: 'none',
                                                }}><img height={15} alt={'sort'}
                                                        src={Sort}/></DropdownToggle>
                                                <DropdownMenu style={{minWidth: '4rem', width: '4rem'}}>
                                                    <DropdownItem
                                                        onClick={() => showEditCommentModal(item)}>Edit</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => showDeleteCommentModal(item)}>Delete</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        }
                                    </div>
                                    <span className={'p-0 mb-4'}
                                          style={{marginLeft: '57px', marginTop: '20px'}}>
                                   {item.message}
                                    </span>
                                    <div className={'like-comment'}
                                         style={{marginLeft: '57px', marginTop: '8px'}}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                            {item.like_count > 0 ?
                                                <>
                                                    {item.current_user_like_status ?
                                                        <i style={{
                                                            fontSize: '14px',
                                                            color: 'purple',
                                                            cursor: 'pointer'
                                                        }}
                                                           onClick={() => handleCommentReplyLike(item, index)}
                                                            // onClick={() => this.handleReplyLikePress(it)}
                                                           className={'fa fa-thumbs-up'}>&nbsp;{item.like_count}</i>
                                                        :
                                                        <i style={{
                                                            fontSize: '14px',
                                                            color: 'grey',
                                                            cursor: 'pointer'
                                                        }}
                                                           onClick={() => handleCommentReplyLike(item, index)}
                                                            // onClick={() => this.handleReplyLikePress(it)}
                                                           className={'fa fa-thumbs-up'}>&nbsp;{item.like_count}</i>
                                                    }
                                                </> :
                                                <i style={{
                                                    fontSize: '14px',
                                                    color: 'grey',
                                                    cursor: 'pointer'
                                                }}
                                                   onClick={() => handleCommentReplyLike(item, index)}
                                                    // onClick={() => this.handleReplyLikePress(it)}
                                                   className={'fa fa-thumbs-up'}>&nbsp;{item.like_count}</i>

                                            }

                                            <i style={{
                                                fontSize: '14px',
                                                color: 'grey',
                                                cursor: 'pointer'
                                            }}
                                               onClick={() => handleCommentReplyCommentShowModal(index)}
                                               className={'ml-6 fa fa-comment'}>&nbsp;{item?.commentreply_count || 0}</i>
                                        </div>
                                        <hr className={'mb-0'}/>

                                        {showCommentReply === index && (
                                            <>
                                                <div style={{
                                                    marginTop: '10px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%'
                                                }}>
                                                    {JSON.parse(localStorage.getItem('user'))?.id !== item.user.id &&
                                                        <>
                                                            <div className={'commentBox'}
                                                                 style={{padding: '10px', width: '100%'}}>
                                                                <Input
                                                                    className={'search-input'}
                                                                    type="text"
                                                                    name="commentReplyMessage"
                                                                    value={commentReplyMessage}
                                                                    onChange={(event) => setCommentReplyMessage(event.target.value)}
                                                                    placeHolder={'Reply a comment'}
                                                                    onKeyDown={(e) => handleReplyKeyDown(e, item)}
                                                                />
                                                            </div>
                                                            <div className={'mt-2'} style={{width: '100%'}}>
                                                                <Button style={{
                                                                    backgroundColor: '#6C32A0',
                                                                    color: 'white'
                                                                }}
                                                                        className={'btn btn-sm float-right'}
                                                                        onClick={() => handleReplyComment(item)}>Comment</Button>
                                                                <Button style={{
                                                                    backgroundColor: '#ff1010',
                                                                    color: 'white'
                                                                }}
                                                                        className={'btn btn-sm float-right'}
                                                                        onClick={() => clearCommentReplyMessage()}>Cancel</Button>
                                                                <div style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'flex-start'
                                                                }}>

                                                                    {commentReplySuccess.commentMessages && <span
                                                                        style={{
                                                                            color: 'red',
                                                                            marginLeft: '10px'
                                                                        }}>{commentReplySuccess.commentMessages}</span>}
                                                                </div>
                                                            </div>
                                                            <br/>
                                                        </>
                                                    }

                                                    {item.reply.map((reply, index) => {
                                                        return (
                                                            <div className={'ml-2'}>
                                                                <div className={'card-image'} key={index}
                                                                     style={{
                                                                         display: 'flex',
                                                                         flexDirection: "row",
                                                                         marginBottom: '6px'
                                                                     }}>
                                                                    <img alt={'review'}
                                                                         src={`https://live.englishhub.app${reply.user.profile_picture}` || avatar}
                                                                         height={50}
                                                                         style={{
                                                                             borderRadius: '50%',
                                                                             objectFit: 'cover'
                                                                         }}
                                                                         width={50}
                                                                    />
                                                                    <div className={'card-name-star'}>
                                                        <span
                                                            className={'ml-2 font-weight-bold'}>{reply.user.full_name}</span>
                                                                        <div className={'star'}>
                                                            <span style={{fontSize: '12px'}} className={'ml-2 mt-0'}>
                                                                 {new Date(reply?.created_at).toLocaleString('en-US',
                                                                     {
                                                                         weekday: 'long',
                                                                         year: 'numeric',
                                                                         month: 'long',
                                                                         day: 'numeric',
                                                                         hour: 'numeric',
                                                                         minute: 'numeric',
                                                                         second: 'numeric'
                                                                     })}</span>
                                                                        </div>
                                                                    </div>
                                                                    {JSON.parse(localStorage.getItem('user'))?.id === reply.user.id &&
                                                                        <Dropdown
                                                                            toggle={() => showEditDeleteReplyToggle(index)}
                                                                            style={{
                                                                                backgroundColor: 'transparent',
                                                                                minWidth: '4rem'
                                                                            }}
                                                                            isOpen={showEditDeleteReply === index ? true : false}>
                                                                            <DropdownToggle style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                justifyContent: 'space-between',
                                                                                alignItems: 'center',
                                                                                backgroundColor: 'transparent',
                                                                                boxShadow: 'none',
                                                                                border: 'none',
                                                                            }}><img height={15} alt={'sort'}
                                                                                    src={Sort}/></DropdownToggle>
                                                                            <DropdownMenu style={{
                                                                                minWidth: '4rem',
                                                                                width: '4rem'
                                                                            }}>
                                                                                <DropdownItem
                                                                                    onClick={() => showEditCommentReplyModal(reply)}>Edit</DropdownItem>
                                                                                <DropdownItem
                                                                                    onClick={() => showDeleteCommentReplyModal(reply)}>Delete</DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                    }
                                                                </div>
                                                                <span className={'p-0 mb-4'}
                                                                      style={{marginLeft: '57px', marginTop: '20px'}}>
                                                                  {reply.message}
                                                                      </span>
                                                                <div className={'like-comment'}
                                                                     style={{marginLeft: '57px', marginTop: '6px'}}>
                                                                    {reply.like_count > 0 ?
                                                                        <>
                                                                            {reply.current_user_like_status ?
                                                                                <i style={{
                                                                                    fontSize: '14px',
                                                                                    color: 'purple',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                   onClick={() => handleReplyCommentLike(reply, index)}
                                                                                    // onClick={() => this.handleReplyLikePress(it)}
                                                                                   className={'fa fa-thumbs-up'}>&nbsp;{reply.like_count}</i>
                                                                                :
                                                                                <i style={{
                                                                                    fontSize: '14px',
                                                                                    color: 'grey',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                   onClick={() => handleReplyCommentLike(reply, index)}
                                                                                    // onClick={() => this.handleReplyLikePress(it)}
                                                                                   className={'fa fa-thumbs-up'}>&nbsp;{reply.like_count}</i>
                                                                            }
                                                                        </> :
                                                                        <i style={{
                                                                            fontSize: '14px',
                                                                            color: 'grey',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                           onClick={() => handleReplyCommentLike(reply, index)}
                                                                            // onClick={() => this.handleReplyLikePress(it)}
                                                                           className={'fa fa-thumbs-up'}>&nbsp;{reply.like_count}</i>
                                                                    }
                                                                    <hr className={'m-1 p-1'}/>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        )
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Modal>
            }

            {showShare &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showShare}
                    toggle={() => setShowShare(!showShare)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                             paddingLeft: '320px'
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Share
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowShare(!showShare)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'p-3 modal-body'}>
                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            border: '1px solid lightgrey',
                            borderRadius: '15px',
                            padding: '15px',
                            boxShadow: 'rgba(215, 215, 215, 0.2) 0px 0px 5px 5px'
                        }}>
                            <div className={'card-image'} style={{display: 'flex', flexDirection: "row"}}>
                                <img alt={'review'}
                                     src={avatar}
                                     height={50}
                                     width={50}
                                />
                                <div className={'card-name-star'}>
                                    <span className={'ml-2 font-weight-bold'}>Ashok Pokhrel</span>
                                    <div className={'star'}>
                <span style={{fontSize: '12px'}} className={'ml-2 mt-0'}>
            {new Date().toDateString()}</span>
                                    </div>
                                </div>
                            </div>
                            <span className={'p-0'} style={{marginTop: '10px'}}>
                Hello This is a share content
                </span>
                            <div className={'mt-3'}
                                 style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <Button style={{width: '100%'}}>Show Attachment</Button>
                            </div>
                            <div className={'mt-3'}
                                 style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                <Button style={{width: '50%'}}>Like</Button>
                                <Button style={{width: '50%'}}>Share</Button>
                            </div>
                        </div>

                    </div>
                </Modal>
            }

            {showDelete &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showDelete}
                    toggle={() => setShowDelete(!showDelete)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Are you sure you want to delete this post? {deleteItem.id}
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowDelete(!showDelete)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-footer'}>
                        <Button onClick={() => deleteCommunityPost()}
                                className={'float-right btn btn-success'}>Confirm</Button>
                        <Button onClick={() => setShowDelete(!showDelete)}
                                className={'float-right btn btn-danger'}>Close</Button>
                    </div>

                </Modal>
            }

            {showEdit &&
                <AnnouncementField
                    showEdit={showEdit}
                    showEditItem={showEditItem}/>

            }


            {showEditReplyComment &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showEditReplyComment}
                    toggle={() => setShowEditReplyComment(!showEditReplyComment)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Edit Reply Comment
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowEditReplyComment(!showEditReplyComment)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-body'}>
                        <div className={'commentBox'} style={{padding: '10px'}}>
                            <Input
                                className={'search-input'}
                                type="text"
                                name="comment"
                                value={editCommentReplyMessage}
                                onChange={(event) => setShowEditCommentReplyMessage(event.target.value)}
                                placeHolder={'Reply a comment'}
                                onKeyDown={(e) => handleCommentReplyKeyDown(e)}
                            />
                        </div>
                    </div>

                    <div className={'modal-footer'}>
                        <Button onClick={() => updateEditCommentReply()}
                                className={'float-right btn btn-success'}>Confirm</Button>
                        <Button onClick={() => setShowEditReplyComment(!showEditReplyComment)}
                                className={'float-right btn btn-danger'}>Close</Button>
                    </div>

                </Modal>
            }

            {showDeleteReplyComment &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showDeleteReplyComment}
                    toggle={() => setShowDeleteReplyComment(!showDeleteReplyComment)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Are you sure you want to delete this Comment?
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowDeleteReplyComment(!showDeleteReplyComment)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-footer'}>
                        <Button onClick={() => deleteCommunityCommentReplyPost()}
                                className={'float-right btn btn-success'}>Confirm</Button>
                        <Button onClick={() => setShowDeleteReplyComment(!showDeleteReplyComment)}
                                className={'float-right btn btn-danger'}>Close</Button>
                    </div>

                </Modal>
            }

            {showEditComment &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showEditComment}
                    toggle={() => setShowEditComment(!showEditComment)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Edit Comment
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowEditComment(!showEditComment)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-body'}>
                        <div className={'commentBox'} style={{padding: '10px'}}>
                            <Input
                                className={'search-input'}
                                type="text"
                                name="comment"
                                value={editCommentMessage}
                                onChange={(event) => setShowEditCommentMessage(event.target.value)}
                                placeHolder={'Reply a comment'}
                                onKeyDown={(e) => handleComment(e)}
                            />
                        </div>
                    </div>

                    <div className={'modal-footer'}>
                        <Button onClick={() => updateEditComment()}
                                className={'float-right btn btn-success'}>Confirm</Button>
                        <Button onClick={() => setShowEditComment(!showEditComment)}
                                className={'float-right btn btn-danger'}>Close</Button>
                    </div>

                </Modal>

            }

            {showDeleteComment &&
                <Modal
                    keyboard={false}
                    backdrop={true}
                    className="modal-dialog-centered modal-lg"
                    isOpen={showDeleteComment}
                    toggle={() => setShowDeleteComment(!showDeleteComment)}
                >
                    <div className="modal-header"
                         style={{
                             borderBottom: '1px solid lightgrey',
                             display: 'flex',
                             justifyContent: 'center',
                             margin: '5px',
                             padding: '10px',
                         }}>
                        <h2 style={{textAlign: 'center'}} className="modal-title" id="modal-title-default">
                            Are you sure you want to delete this Comment?
                        </h2>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setShowDeleteComment(!showDeleteComment)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>

                    <div className={'modal-footer'}>
                        <Button onClick={() => deleteCommunityCommentPost()}
                                className={'float-right btn btn-success'}>Confirm</Button>
                        <Button onClick={() => setShowDeleteComment(!showDeleteComment)}
                                className={'float-right btn btn-danger'}>Close</Button>
                    </div>

                </Modal>
            }

        </>
    )
}

export default withSnackbar(CommunityPostList)
